body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.contact_cv {
  border: 1px solid black;
  margin: 15px;
  float: left;
  padding: 15px;
}


.fullHeight, body, .ant-layout, #root {
  height:100%;
  background-color: rgb(240, 242, 245);
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-20 10:29:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-horizontal-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
}
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
}


@-webkit-keyframes flip-horizontal-top {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
}
@keyframes flip-horizontal-top {
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
}


.flip-horizontal-bottom {
	-webkit-animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}
.flip-horizontal-top {
	-webkit-animation: flip-horizontal-top 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: flip-horizontal-top 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

