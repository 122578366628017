.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.screener .description {
  max-width: 700px;
}
.screener .name {
  max-width: 100px;
}
.screener .flaggingReasons {
  max-width: 150px;
}
.screener .buttonGroup {
  width:160px!important
}

.ant-input {
  min-width: 210px;
}
/* stupid hack to have only 4 stars rating with ant design, sry */
.ant-rate-star:last-child {
  display: none;
}

.ant-form-item {
  margin-bottom: 0;
}

.contact_cv {
  width: calc(50% - 60px);
  border: 1px solid black;
  margin: 15px;
  float: left;
  padding: 15px;
}

.position {
  margin: 20px 0;
}

.posTitle {
  font-size: 18px;

}
.posDate {

  --black: #000;
  --black-a90: rgba(0,0,0,0.9);
  --black-a75: rgba(0,0,0,0.75);
  --black-a60: rgba(0,0,0,0.6);
  --black-a45: rgba(0,0,0,0.45);
  --black-a30: rgba(0,0,0,0.3);
  --black-a15: rgba(0,0,0,0.15);
  --black-a12: rgba(0,0,0,0.12);
  --black-a08: rgba(0,0,0,0.08);
  --black-a04: rgba(0,0,0,0.04);
  --white: #fff;
  --white-a90: hsla(0,0%,100%,0.9);
  --white-a85: hsla(0,0%,100%,0.85);
  --white-a75: hsla(0,0%,100%,0.75);
  --white-a70: hsla(0,0%,100%,0.7);
  --white-a60: hsla(0,0%,100%,0.6);
  --white-a55: hsla(0,0%,100%,0.55);
  --white-a40: hsla(0,0%,100%,0.4);
  --white-a25: hsla(0,0%,100%,0.25);
  --white-a18: hsla(0,0%,100%,0.18);
  --white-a12: hsla(0,0%,100%,0.12);
  --white-a06: hsla(0,0%,100%,0.06);
  --blue-10: #f6fbff;
  --blue-20: #e8f3ff;
  --blue-30: #d0e8ff;
  --blue-40: #a8d4ff;
  --blue-50: #70b5f9;
  --blue-50-a20: rgba(112,181,249,0.2);
  --blue-50-a30: rgba(112,181,249,0.3);
  --blue-60: #378fe9;
  --blue-70: #0a66c2;
  --blue-80: #004182;
  --blue-90: #09223b;
  --cool-gray-10: #f9fafb;
  --cool-gray-20: #eef3f8;
  --cool-gray-30: #dce6f1;
  --cool-gray-40: #c0d1e2;
  --cool-gray-50: #9db3c8;
  --cool-gray-60: #788fa5;
  --cool-gray-70: #56687a;
  --cool-gray-80: #38434f;
  --cool-gray-85: #293138;
  --cool-gray-90: #1d2226;
  --warm-gray-10: #fafaf9;
  --warm-gray-20: #f3f2ef;
  --warm-gray-30: #e9e5df;
  --warm-gray-40: #d6cec2;
  --warm-gray-40-a50: rgba(214,206,194,0.5);
  --warm-gray-50: #b9af9f;
  --warm-gray-60: #958b7b;
  --warm-gray-70: #6e6558;
  --warm-gray-80: #474139;
  --warm-gray-90: #211f1c;
  --warm-red-10: #fff9f7;
  --warm-red-20: #ffefea;
  --warm-red-30: #ffded5;
  --warm-red-40: #fdc2b1;
  --warm-red-50: #f5987e;
  --warm-red-60: #e16745;
  --warm-red-70: #b24020;
  --warm-red-80: #762812;
  --warm-red-90: #351a12;
  --teal-10: #eefdff;
  --teal-20: #d5f9fe;
  --teal-30: #aef0fa;
  --teal-40: #79deee;
  --teal-50: #44bfd3;
  --teal-50-a30: rgba(68,191,211,0.3);
  --teal-60: #2199ac;
  --teal-70: #13707e;
  --teal-80: #104952;
  --teal-90: #0e2428;
  --purple-10: #fcf9ff;
  --purple-20: #f7efff;
  --purple-30: #eedfff;
  --purple-40: #dec5fd;
  --purple-40-a15: rgba(222,197,253,0.15);
  --purple-50: #c79ef7;
  --purple-60: #a872e8;
  --purple-70: #8344cc;
  --purple-70-a15: rgba(131,68,204,0.15);
  --purple-80: #592099;
  --purple-90: #2c1349;
  --system-red-10: #fff9f9;
  --system-red-20: #ffeeef;
  --system-red-30: #ffddde;
  --system-red-40: #ffbfc1;
  --system-red-50: #fc9295;
  --system-red-60: #f55257;
  --system-red-70: #cc1016;
  --system-red-80: #8a0005;
  --system-red-90: #46080a;
  --system-green-10: #f0fdf7;
  --system-green-20: #dbf9eb;
  --system-green-30: #b6f2d6;
  --system-green-40: #7ce3b3;
  --system-green-50: #3ec786;
  --system-green-60: #13a05f;
  --system-green-70: #057642;
  --system-green-80: #004d2a;
  --system-green-90: #022716;
  --pink-10: #fff8ff;
  --pink-20: #ffedfe;
  --pink-30: #ffdafd;
  --pink-40: #fcb9f9;
  --pink-50: #f489ee;
  --pink-60: #dd51d6;
  --pink-70: #b11faa;
  --pink-80: #770c72;
  --pink-90: #3d0a3b;
  --amber-10: #fdfaf5;
  --amber-20: #fbf1e2;
  --amber-30: #fce2ba;
  --amber-40: #f8c77e;
  --amber-50: #e7a33e;
  --amber-60: #c37d16;
  --amber-70: #915907;
  --amber-80: #5d3b09;
  --amber-90: #2a1f0e;
  --copper-10: #fcfaf9;
  --copper-20: #fcf0ed;
  --copper-30: #fadfd8;
  --copper-40: #f2c5b8;
  --copper-50: #e0a191;
  --copper-60: #be7b6a;
  --copper-70: #8f5849;
  --copper-80: #5d392f;
  --copper-90: #2d1d19;
  --green-10: #f6fcf4;
  --green-20: #e3f9d8;
  --green-30: #c7f1b2;
  --green-40: #a5df89;
  --green-50: #7fc15e;
  --green-60: #5f9b41;
  --green-70: #44712e;
  --green-80: #2f4922;
  --green-90: #1b2416;
  --sage-10: #f8fbf6;
  --sage-20: #eaf6e4;
  --sage-30: #d7ebce;
  --sage-40: #bdd7b0;
  --sage-50: #9db88f;
  --sage-60: #7b9370;
  --sage-70: #5a6b51;
  --sage-80: #3a4535;
  --sage-90: #1e221c;
  --lime-10: #f9fce9;
  --lime-20: #eff8b9;
  --lime-30: #dfee89;
  --lime-40: #c6d957;
  --lime-50: #a6ba32;
  --lime-60: #83941f;
  --lime-70: #5f6c16;
  --lime-80: #3e4613;
  --lime-90: #20230f;
  --camo-10: #fafbf3;
  --camo-20: #f1f4e4;
  --camo-30: #e4e8cc;
  --camo-40: #cdd3ac;
  --camo-50: #aeb48a;
  --camo-60: #8a8f6c;
  --camo-70: #65684e;
  --camo-80: #414335;
  --camo-90: #21211d;
  --smoke-10: #f8fafb;
  --smoke-20: #edf3f4;
  --smoke-30: #dbe7e9;
  --smoke-40: #bfd3d6;
  --smoke-50: #a0b4b7;
  --smoke-60: #7d8f92;
  --smoke-70: #5b696b;
  --smoke-80: #3c4345;
  --smoke-90: #1f2122;
  --lavender-10: #fbf9fd;
  --lavender-20: #f4f1f9;
  --lavender-30: #eae2f3;
  --lavender-40: #d7cae7;
  --lavender-50: #bba9d1;
  --lavender-60: #9983b1;
  --lavender-70: #715e86;
  --lavender-80: #493d57;
  --lavender-90: #241f29;
  --mauve-10: #fcf9fc;
  --mauve-20: #f9eff8;
  --mauve-30: #f2e0f1;
  --mauve-40: #e5c6e3;
  --mauve-50: #cea2cc;
  --mauve-60: #ac7da9;
  --mauve-70: #80597e;
  --mauve-80: #523a51;
  --mauve-90: #271e27;
  --system-gray-10: #fafafa;
  --system-gray-20: #f2f2f2;
  --system-gray-30: #e5e5e5;
  --system-gray-40: #cfcfcf;
  --system-gray-50: #b0b0b0;
  --system-gray-60: #8c8c8c;
  --system-gray-70: #666;
  --system-gray-80: #424242;
  --system-gray-90: #212121;
  --system-orange-10: #fff9f6;
  --system-orange-20: #ffefe8;
  --system-orange-30: #ffdfd1;
  --system-orange-40: #ffc1a7;
  --system-orange-50: #ff9466;
  --system-orange-60: #eb6126;
  --system-orange-70: #b93a04;
  --system-orange-80: #792603;
  --system-orange-90: #351a0f;
  --transparent: transparent;
  --transparent-white: hsla(0,0%,100%,0);
  --transparent-black: transparent;
  --elevation-flat: none;
  --elevation-lined: 0px 0px 0px 1px var(--color-border-faint);
  --elevation-raised: var(--elevation-lined),0px 4px 4px var(--color-shadow);
  --voyager-elevation-text-theater: 0 0 2px var(--black-a60);
  --illustration-microspot-size-small: 4.8rem;
  --illustration-microspot-size-large: 6.4rem;
  --illustration-spot-size-small: 12.8rem;
  --illustration-spot-size-large: 25.6rem;
  --background-icons: url(/sc/h/cwn0a0e7hog2i33c88ucrvot5);
  --entity-background-company: url(/sc/h/3esmlcvt3d6hd63qnh3gm04zr);
  --entity-background-event: url(/sc/h/40ac0813fgj0sf8tv3gtu3faq);
  --entity-background-group: url(/sc/h/68ao3xk5x5pa9fwrs0al41kf0);
  --entity-background-hashtag: url(/sc/h/eb2snh0c2ji8vzcnh9lw1us0u);
  --entity-background-person: url(/sc/h/55k1z8997gh8dwtihm11aajyq);
  --entity-background-product: url(/sc/h/ctt9jbwul88s4jacnglj7d05a);
  --entity-background-publication: url(/sc/h/5kujyv5ot64u9pzg2sqvjoi58);
  --entity-background-school: url(/sc/h/9ufjgh226ft1cnbejutpwq15g);
  --entity-background-service: url(/sc/h/4iujhggnxexx9c0qvg1fvth9p);
  --entity-ghost-company-accent-4: url(/sc/h/aajlclc14rr2scznz5qm2rj9u);
  --entity-ghost-event-accent-4: url(/sc/h/1zv2kdrh9aknn1aeeoiyu9xjd);
  --entity-ghost-group-accent-4: url(/sc/h/5v7kdqzhyyiogppftp4sj6sa0);
  --entity-ghost-hashtag-accent-4: url(/sc/h/3k1pakppc0nte3oi73ssc9g36);
  --entity-ghost-person-accent-4: url(/sc/h/1c5u578iilxfi4m4dvc4q810q);
  --entity-ghost-product-accent-4: url(/sc/h/21koisx415v3d520ivemgq4gl);
  --entity-ghost-publication-accent-4: url(/sc/h/6xjsdd2ltc6idxa5vatv48l9t);
  --entity-ghost-school-accent-4: url(/sc/h/8zzzkhxduv0r11cuxbs48pg03);
  --entity-ghost-service-accent-4: url(/sc/h/6puxblwmhnodu6fjircz4dn4h);
  --illustration-microspot-hashtag-small: url(/sc/h/7ejz9tq5ih0aqk5n7o5fh4s4i);
  --illustration-microspot-hashtag-large: url(/sc/h/dudleh13o5nn2uyi65kaanu3a);
  --illustration-microspot-lightbulb-small: url(/sc/h/e9rg3hopw217xdl7ttpfe4n0h);
  --illustration-microspot-lightbulb-large: url(/sc/h/9bg1fjgy8dxc8r2amgr84razu);
  --illustration-microspot-document-small: url(/sc/h/e8pln9y7iwbg43fvpt2txv4bo);
  --illustration-microspot-document-large: url(/sc/h/eoc15amc0655v05wpqt4e1uo2);
  --illustration-microspot-document-report-small: url(/sc/h/7ef0vb5sdpu2k272yzyslvglw);
  --illustration-microspot-document-report-large: url(/sc/h/y6arcqx4howzfovu9h8j5rqf);
  --illustration-microspot-document-comment-small: url(/sc/h/8dmlga3la3ygqx6l5lwdluv2r);
  --illustration-microspot-document-comment-large: url(/sc/h/9um2v5eapgrhksz97ye2hddxw);
  --illustration-microspot-document-folder-small: url(/sc/h/10f0z1iipiocksc0dcsjvmzu2);
  --illustration-microspot-document-folder-large: url(/sc/h/eg6thjg3ylpjc78l2c8aek9hz);
  --illustration-microspot-industry-small: url(/sc/h/2eqhs5s2227xlfo0ef57a65gz);
  --illustration-microspot-industry-large: url(/sc/h/dbog2z2tz78iudo1tal77aoue);
  --illustration-microspot-search-small: url(/sc/h/mt1w2flx0k71hccryykgc380);
  --illustration-microspot-search-large: url(/sc/h/ebfndg6cj8v96c34j90he7a8n);
  --illustration-microspot-messages-small: url(/sc/h/4xgz1lodolnobqbdwahc5lcok);
  --illustration-microspot-messages-large: url(/sc/h/9be6yapu60eewxr7yyjoz7gcg);
  --illustration-microspot-folder-small: url(/sc/h/cexonatmgw0023ih8eiepu5dc);
  --illustration-microspot-notebook-large: url(/sc/h/e8delr5oqs06ri0kmukauuhef);
  --illustration-microspot-signal-success-small: url(/sc/h/eq5upsoyhik1xp7wxey4vxx99);
  --illustration-microspot-signal-success-large: url(/sc/h/6k4hbk2lzws8uhnc9yjchhzdq);
  --illustration-microspot-signal-caution-small: url(/sc/h/zkxjisg3kt8cg8fid92tikzw);
  --illustration-microspot-signal-caution-large: url(/sc/h/3wh31k243lyxytn0xycfsuotq);
  --illustration-microspot-signal-error-small: url(/sc/h/3ezp0azfj3frbhbvjm410zg3o);
  --illustration-microspot-signal-error-large: url(/sc/h/5p6xr5jd1gq5lprg8py94rcqc);
  --illustration-microspot-company-small: url(/sc/h/8j7g1qg4ygqnysr4hplvebpx7);
  --illustration-microspot-company-large: url(/sc/h/awchc0rh1msj8zy3w7mwdrlvg);
  --illustration-microspot-camera-small: url(/sc/h/3h0vrtch1zepjr4p54aja8i9x);
  --illustration-microspot-camera-large: url(/sc/h/e9nby9wq8ivibcq50g0xohkx6);
  --illustration-microspot-globe-small: url(/sc/h/7o1j81vgoptxe97tbdydvvgd4);
  --illustration-microspot-globe-large: url(/sc/h/3nasbr3sfph9nk8vf9z7hhyyl);
  --illustration-microspot-magnet-small: url(/sc/h/5jgwzc0w6rb4cenqdap5jau21);
  --illustration-microspot-magnet-large: url(/sc/h/48i3p8rhwfua3rkfsevkkn7e6);
  --illustration-microspot-rocket-small: url(/sc/h/8v6vj4ynjtrf4p8gqybbtdt1e);
  --illustration-microspot-rocket-large: url(/sc/h/7izadu8b2iscoyubl1fv0f2rr);
  --illustration-microspot-trophy-small: url(/sc/h/64fnhrjqilkkiipbdmtq6cysv);
  --illustration-microspot-trophy-large: url(/sc/h/qfqsvknd3eqkbctcr6ind2it);
  --illustration-microspot-location-marker-small: url(/sc/h/7lphf50k5fz0ryidvum0k4ej1);
  --illustration-microspot-location-marker-large: url(/sc/h/61b8abmtp6ykr7dx9urt5djit);
  --illustration-microspot-salary-small: url(/sc/h/6z0jkwl7jtxo832agimeu3rzu);
  --illustration-microspot-salary-large: url(/sc/h/aqy8l20l3w5ew9ir3tca68hss);
  --illustration-microspot-pencil-ruler-small: url(/sc/h/dqusxrvz5xy0ffkawdzpcng0e);
  --illustration-microspot-pencil-ruler-large: url(/sc/h/bagy21yuohuw9upte0iyjkwnf);
  --illustration-microspot-megaphone-small: url(/sc/h/c1o7l42lkcj5pkt5zsh0qfg3p);
  --illustration-microspot-megaphone-large: url(/sc/h/thodlizq3n5cnwncfr913800);
  --illustration-microspot-school-small: url(/sc/h/5c9e0v28ilvp9ocpnqbfm7ll2);
  --illustration-microspot-school-large: url(/sc/h/e28lwgugizrkiw19t33qcdw5n);
  --illustration-microspot-video-small: url(/sc/h/du2j1l9egswicx210ns4cls0n);
  --illustration-microspot-video-large: url(/sc/h/f33a4mwlx1kto0k1ocuxdi7x3);
  --illustration-microspot-video-course-small: url(/sc/h/yroopzlq3zyu06lwnn3mlnne);
  --illustration-microspot-video-course-large: url(/sc/h/be0qvs4zumessk8zui24p4ol6);
  --illustration-microspot-dashboard-small: url(/sc/h/2nozouqfl4dxqy8iv6mergx0v);
  --illustration-microspot-dashboard-large: url(/sc/h/iv9zspfl6eqxv15ovfn2t358);
  --illustration-microspot-briefcase-jobs-small: url(/sc/h/7kdt0x9c7x5fpi05dgp7bpj1h);
  --illustration-microspot-briefcase-jobs-large: url(/sc/h/453fxzefqctfzqpp1whfuqmes);
  --illustration-microspot-mail-small: url(/sc/h/9wstsyhok0eztdb17fnl7id27);
  --illustration-microspot-mail-large: url(/sc/h/2efz9zl9evxhi93a348c0miu6);
  --illustration-microspot-mail-open-small: url(/sc/h/by25qalc5r0mj0xgtyvct77mm);
  --illustration-microspot-mail-open-large: url(/sc/h/9skgt60lcdy5cpesypn3dk0jo);
  --illustration-microspot-article-small: url(/sc/h/ob1kf016vuxdwngitvgyd4qo);
  --illustration-microspot-article-large: url(/sc/h/6x5fknk8aw01lsn6othvh3x6);
  --illustration-microspot-article-stack-small: url(/sc/h/eh9l4y2y9egq255icdj73ap53);
  --illustration-microspot-article-stack-large: url(/sc/h/f11v1qjrri2wv7j3p61nisjwy);
  --illustration-microspot-award-small: url(/sc/h/bsk70m6veu0ebq46me6wu9irc);
  --illustration-microspot-award-large: url(/sc/h/fbb14fisovfwyywab84807dv);
  --illustration-microspot-calendar-small: url(/sc/h/ezxn6qry476i27vd1gmg4zu13);
  --illustration-microspot-calendar-large: url(/sc/h/8bllydfsob2rj0bb6tjapbtwc);
  --illustration-microspot-compass-small: url(/sc/h/8qiomz142bw4aog6vudglq8rq);
  --illustration-microspot-compass-large: url(/sc/h/6ket5ufv5f5yqa01ty9w8yopz);
  --illustration-microspot-glasses-small: url(/sc/h/25wf7qj61f3qw2at8ruktsoe5);
  --illustration-microspot-glasses-large: url(/sc/h/395sxe7s63tjcv9nrqtmh832c);
  --illustration-microspot-gift-small: url(/sc/h/6z1ib58raepv1en7l3gq8fdjq);
  --illustration-microspot-gift-large: url(/sc/h/35omcvhr015yq1izs08suq7n4);
  --illustration-microspot-id-badge-small: url(/sc/h/74yh6g7i047cvp9us08bq9zq9);
  --illustration-microspot-id-badge-large: url(/sc/h/dvseklwrqghkt6ztuw2ju21bt);
  --illustration-microspot-image-small: url(/sc/h/2jgp12jkw69qzxpu3r0rvbw01);
  --illustration-microspot-image-large: url(/sc/h/cjvkdn7ksnohjev0ubt69ihl);
  --illustration-microspot-image-stack-small: url(/sc/h/bwnxgpwtmrrnt8q7edbfyyz73);
  --illustration-microspot-image-stack-large: url(/sc/h/7dlzkmhb2dckvymvqoyuvwwri);
  --illustration-microspot-dartboard-small: url(/sc/h/blxmpdkilc2ew7i6rzywpp6ix);
  --illustration-microspot-dartboard-large: url(/sc/h/49v9h35mq49shqan9ly966fk0);
  --illustration-microspot-shield-small: url(/sc/h/773niqkfqud98p0iux16pbem8);
  --illustration-microspot-shield-large: url(/sc/h/diiwnu6zz9s9e3ir33obm62zk);
  --illustration-microspot-slides-stack-small: url(/sc/h/cnpfrl0ovcq08l5x684cwbegg);
  --illustration-microspot-slides-stack-large: url(/sc/h/46gpniex8043a3j9c0rvl4goa);
  --illustration-microspot-unlocked-small: url(/sc/h/boe5l15yh3n4z8ift2aqsn02u);
  --illustration-microspot-unlocked-large: url(/sc/h/8mw55rgt3lv9efjq25zldedi2);
  --illustration-microspot-notepad-small: url(/sc/h/ae7rcl1e4k3763p3girmgon60);
  --illustration-microspot-notepad-large: url(/sc/h/uxrni9bban47a616u5r9wles);
  --illustration-microspot-address-book-small: url(/sc/h/3l95eldla4jkvfwgtu88vsc7y);
  --illustration-microspot-address-book-large: url(/sc/h/besi5ttup7xai3gohkhsmrsef);
  --illustration-microspot-patent-small: url(/sc/h/9vztihtsda1yp0ymmky7vh76);
  --illustration-microspot-patent-large: url(/sc/h/e1ipnnv67bjaflo7cfnk3fex2);
  --illustration-microspot-flowers-small: url(/sc/h/cai1hcdk4996rg1eb4ibe9v63);
  --illustration-microspot-flowers-large: url(/sc/h/1f6av72ez8ppbk5t7bv0phg2s);
  --illustration-microspot-locked-small: url(/sc/h/i93f52dd53ubd4bxuojkwdal);
  --illustration-microspot-locked-large: url(/sc/h/2y9nqxqwwensts5chxdtbbryl);
  --illustration-microspot-phone-small: url(/sc/h/3iswmjqb4cgrcjrwun2yy1xve);
  --illustration-microspot-phone-large: url(/sc/h/3h2dwz26xcial9r4matf619qh);
  --illustration-microspot-toolbox-small: url(/sc/h/1jsj36kpizr74p7cg1nluxao9);
  --illustration-microspot-toolbox-large: url(/sc/h/dtsd51ai7tql6mtsazq4s1x8x);
  --illustration-microspot-certificate-small: url(/sc/h/cbkh7flqwu3c4op5s4evgbce6);
  --illustration-microspot-certificate-large: url(/sc/h/aeeyejibnqlccu5ax3s3dr1wk);
  --illustration-microspot-birthday-small: url(/sc/h/998lfczwj3ve57ye3ehucwavx);
  --illustration-microspot-birthday-large: url(/sc/h/af3v61b9m3c8naty2py4wm77r);
  --illustration-microspot-binders-small: url(/sc/h/194rztseb7jqvnc8cpnx4r6zo);
  --illustration-microspot-binders-large: url(/sc/h/bs0yhoi6n0p0pjorxeodj5d3w);
  --illustration-microspot-ui-feed-profile-small: url(/sc/h/e3wsczd1kagrx4moj5r5cbijl);
  --illustration-microspot-ui-feed-profile-large: url(/sc/h/6z802sdc4gk9gjfpicuymg00f);
  --illustration-microspot-ui-chart-small: url(/sc/h/1u3pos2bx4lyf62u2asd2zpej);
  --illustration-microspot-ui-chart-large: url(/sc/h/991lxm66zvskb4b0oqiv6xv5g);
  --illustration-microspot-ui-dashboard-small: url(/sc/h/2nozouqfl4dxqy8iv6mergx0v);
  --illustration-microspot-ui-dashboard-large: url(/sc/h/iv9zspfl6eqxv15ovfn2t358);
  --illustration-spot-main-wfh-small: url(/sc/h/832it0s16hwg6jejkfm29fx3m);
  --illustration-spot-main-wfh-large: url(/sc/h/at79od4vx7zjbe5v8qzdj0m4f);
  --illustration-spot-main-wfh-video-small: url(/sc/h/7k5j65hp0zfnfg8f71vv9himy);
  --illustration-spot-main-wfh-video-large: url(/sc/h/3ylugssxayjc885putv1kl9fg);
  --illustration-spot-empty-leaving-small: url(/sc/h/3ggbqks4oh7irvgrgu4lvvudi);
  --illustration-spot-empty-leaving-large: url(/sc/h/94uwnfdql5f1il4c6bowonpd2);
  --illustration-spot-error-construction-small: url(/sc/h/bpw4x301sj2509tdlfz1oojut);
  --illustration-spot-error-construction-large: url(/sc/h/3hyml9do3cyvs6ehj31cmygd6);
  --illustration-spot-main-broadcast-small: url(/sc/h/73hg693mowoj4e23phyhy7zyc);
  --illustration-spot-main-broadcast-large: url(/sc/h/57r2jki3shbkyetajobq6bqcx);
  --illustration-spot-main-coworkers-small: url(/sc/h/axgduq35g9cjimgrc6383gx61);
  --illustration-spot-main-coworkers-large: url(/sc/h/acq3qqdo5efui5ruti3za15s1);
  --illustration-spot-main-coworkers-2-small: url(/sc/h/rn9obfqh70pb7776ajlqlb8);
  --illustration-spot-main-coworkers-2-large: url(/sc/h/9j2yx29beni5re5hcsu7hd58y);
  --illustration-spot-main-coworkers-3-small: url(/sc/h/1g0i2391xf7f5nchlw6k672v5);
  --illustration-spot-main-coworkers-3-large: url(/sc/h/19uchd64y05us1qm28anl8gma);
  --illustration-spot-main-coworkers-4-small: url(/sc/h/4jvhod4eq7jffh8i9km2gz9w0);
  --illustration-spot-main-coworkers-4-large: url(/sc/h/5idncmqew71ebp4jsarv3oiq9);
  --illustration-spot-main-coworkers-5-small: url(/sc/h/tx3x7xynlkzfxwrz7dd9znrr);
  --illustration-spot-main-coworkers-5-large: url(/sc/h/e6d8pqfft0d4qnql1eygjkhug);
  --illustration-spot-main-presentation-small: url(/sc/h/6vau5c5nq24iu4uh5g70yc8y1);
  --illustration-spot-main-presentation-large: url(/sc/h/b64enp7ybpnyltqz0i3k4sjk0);
  --illustration-spot-empty-no-mail-small: url(/sc/h/a7ifaypxl4xqge2siol4oz8jf);
  --illustration-spot-empty-no-mail-large: url(/sc/h/eeaz4pxo41dwponbz8xm17a6f);
  --illustration-spot-error-crossing-small: url(/sc/h/69gnpmrt6ip05vc2ltpfhx663);
  --illustration-spot-error-crossing-large: url(/sc/h/8i98g2soac4ux8gzeedm8uxod);
  --illustration-spot-main-collaboration-small: url(/sc/h/4bet5qswki7f8kp8jfmemsa7j);
  --illustration-spot-main-collaboration-large: url(/sc/h/54rbceijpjz41pl79m9tbrw0k);
  --illustration-spot-main-relax-small: url(/sc/h/8svsx2hvtzdlwqedt21iaa17e);
  --illustration-spot-main-relax-large: url(/sc/h/bc4ch6770wc6clh8rhqfyemjo);
  --illustration-spot-empty-room-small: url(/sc/h/8ho1mw83gfmgiq4yr8f8wkjmh);
  --illustration-spot-empty-room-large: url(/sc/h/60v3z2w447yzbb0noj907w634);
  --illustration-spot-error-fail-small: url(/sc/h/8kbilx26winmlrx3vpmj0pvkz);
  --illustration-spot-error-fail-large: url(/sc/h/1o8878ioi0n0oejl0qs2wm78v);
  --illustration-spot-main-commute-small: url(/sc/h/8neleqqf8suqptjnbfrmww1v3);
  --illustration-spot-main-commute-large: url(/sc/h/bgjzeyr8zfdaxq7lihevrd652);
  --illustration-spot-success-individual-small: url(/sc/h/2bzqs373a54t0txoddnaif5yb);
  --illustration-spot-success-individual-large: url(/sc/h/b1bzd30tu2eoxlexybcabfd5q);
  --illustration-spot-success-individual-2-small: url(/sc/h/88zo2hoeuh39huiif78nybdgz);
  --illustration-spot-success-individual-2-large: url(/sc/h/e4otsuam9qjp5zdzu6gow66wf);
  --illustration-spot-empty-waiting-small: url(/sc/h/awfqc67t42p28qr5oetysabdg);
  --illustration-spot-empty-waiting-large: url(/sc/h/bjy51nb1zfbdz51kyu3wo1lis);
  --illustration-spot-error-pit-crew-small: url(/sc/h/6x6j7pqndbcgmleqrmp546vow);
  --illustration-spot-error-pit-crew-large: url(/sc/h/8m9p3sk7tsj5u05ssjcyobaay);
  --illustration-spot-main-conversation-small: url(/sc/h/1o9e66lkp405mld4h1iwmmkkr);
  --illustration-spot-main-conversation-large: url(/sc/h/8wkc25ga18trec0wmc1f3x2sf);
  --illustration-spot-error-connection-small: url(/sc/h/eeonurm6djfuhvlyhkx4a2qz2);
  --illustration-spot-error-connection-large: url(/sc/h/3qwd8xp56jeql1bdr2h136i16);
  --illustration-spot-error-server-small: url(/sc/h/35g21lbobq614u53uejo9treo);
  --illustration-spot-error-server-large: url(/sc/h/3p7p6a7q99wymlzghijta8d3p);
  --illustration-spot-main-person-small: url(/sc/h/6cgv7suj4518uqu9zolh4dmom);
  --illustration-spot-main-person-large: url(/sc/h/5lhfihank7tk7v3ij0ideeex9);
  --illustration-spot-main-person-2-small: url(/sc/h/9750rb8sf2qxwlapv61wmppns);
  --illustration-spot-main-person-2-large: url(/sc/h/69rgy8n2tij96s455ydn2zqca);
  --illustration-spot-main-person-3-small: url(/sc/h/4thfp6qqvusb5gdvq3exzgkrz);
  --illustration-spot-main-person-3-large: url(/sc/h/ebahpd6dzt7e6vu515mpmskpt);
  --illustration-spot-success-team-small: url(/sc/h/dtt2wfjcwbg783erhf9s5qgwx);
  --illustration-spot-success-team-large: url(/sc/h/a2pyfi1l1b4ztjwd0zbbtrmfw);
  --reaction-empathy-consumption-ring-small: url(/sc/h/7y5llkdpxktvffo2q2d1ni7s9);
  --reaction-empathy-consumption-ring-medium: url(/sc/h/ei7aklvzt7dr0igafj01rotus);
  --reaction-interest-consumption-ring-small: url(/sc/h/8dt8s1behx6ih6nda1uez7md1);
  --reaction-interest-consumption-ring-medium: url(/sc/h/25e49bdtvgm3b4m1hvchlc8vi);
  --reaction-like-consumption-ring-small: url(/sc/h/4c1dzspg0yqfip47a9y26tnx8);
  --reaction-like-consumption-ring-medium: url(/sc/h/3lqizj7iznogmix310hu6yk3m);
  --reaction-maybe-consumption-ring-small: url(/sc/h/4ve1ypy9tv5k21myzkcbu9p3y);
  --reaction-maybe-consumption-ring-medium: url(/sc/h/3395odw21t3476sfd95p4q4d6);
  --reaction-praise-consumption-ring-small: url(/sc/h/72p0gef8k748mc8rq861vx9bh);
  --reaction-praise-consumption-ring-medium: url(/sc/h/ueh8hipnjjxmsi5ap60s7887);
  --reaction-support-consumption-ring-small: url(/sc/h/95fexuy2ghirrrdv9hkn489no);
  --reaction-support-consumption-ring-medium: url(/sc/h/89wkqfdy9c24kwmjf62krf83d);
  --reaction-empathy-consumption-small: url(/sc/h/cpho5fghnpme8epox8rdcds22);
  --reaction-empathy-consumption-medium: url(/sc/h/f58e354mjsjpdd67eq51cuh49);
  --reaction-empathy-consumption-large: url(/sc/h/6f5qp9agugsqw1swegjxj86me);
  --reaction-interest-consumption-small: url(/sc/h/lhxmwiwoag9qepsh4nc28zus);
  --reaction-interest-consumption-medium: url(/sc/h/6gz02r6oxefigck4ye888wosd);
  --reaction-interest-consumption-large: url(/sc/h/9ry9ng73p660hsehml9i440b2);
  --reaction-like-consumption-small: url(/sc/h/8ekq8gho1ruaf8i7f86vd1ftt);
  --reaction-like-consumption-medium: url(/sc/h/2uxqgankkcxm505qn812vqyss);
  --reaction-like-consumption-large: url(/sc/h/8fz8rainn3wh49ad6ef9gotj1);
  --reaction-maybe-consumption-small: url(/sc/h/4mv33903v0o9ikpwfuy2ftcc6);
  --reaction-maybe-consumption-medium: url(/sc/h/4rw9bhaoyh1622ce65fshynuw);
  --reaction-maybe-consumption-large: url(/sc/h/2724p6g37kw0lylnp9ikstice);
  --reaction-praise-consumption-small: url(/sc/h/b1dl5jk88euc7e9ri50xy5qo8);
  --reaction-praise-consumption-medium: url(/sc/h/cm8d2ytayynyhw5ieaare0tl3);
  --reaction-praise-consumption-large: url(/sc/h/1mbfgcprj3z93pjntukfqbr8y);
  --reaction-support-consumption-small: url(/sc/h/3wqhxqtk2l554o70ur3kessf1);
  --reaction-support-consumption-medium: url(/sc/h/e1vzxs43e7ryd6jfvu7naocd2);
  --reaction-support-consumption-large: url(/sc/h/cv29x2jo14dbflduuli6de6bf);
  --reaction-empathy-creation-medium: url(/sc/h/959e0f6ksc4uolqnht9kp911s);
  --reaction-empathy-creation-large: url(/sc/h/asmf650x603bcwgefb4heo6bm);
  --reaction-empathy-creation-extra-large: url(/sc/h/9oojx2j6mm04oubp0lwu09k9j);
  --reaction-interest-creation-medium: url(/sc/h/60nfg4p6apn8s53zmsoc2qxrb);
  --reaction-interest-creation-large: url(/sc/h/39axkb4qe8q95ieljrhqhkxvl);
  --reaction-interest-creation-extra-large: url(/sc/h/309j5ty10wehgnhnu8d5ekgvb);
  --reaction-like-creation-medium: url(/sc/h/5zhd32fqi5pxwzsz78iui643e);
  --reaction-like-creation-large: url(/sc/h/f4ly07ldn7194ciimghrumv3l);
  --reaction-like-creation-extra-large: url(/sc/h/14gu29noxwssk9frd8z3dl7uk);
  --reaction-maybe-creation-medium: url(/sc/h/9v68n3giwr4el6ep9ada2fqcw);
  --reaction-maybe-creation-large: url(/sc/h/1z80ze8ler6arc76a8rxsgqbh);
  --reaction-maybe-creation-extra-large: url(/sc/h/esguzme5vk2dzoobigz5eli7l);
  --reaction-praise-creation-medium: url(/sc/h/4vr7dcac5wuy4nwsxxx8tf9e7);
  --reaction-praise-creation-large: url(/sc/h/3c4dl0u9dy2zjlon6tf5jxlqo);
  --reaction-praise-creation-extra-large: url(/sc/h/33mzzxfnhw0z485ih2rf9y8jq);
  --reaction-support-creation-medium: url(/sc/h/dmu7wat3wrn1xm59wg46ucrb1);
  --reaction-support-creation-large: url(/sc/h/9whrgl1hq2kfxjqr9gqwoqrdi);
  --reaction-support-creation-extra-large: url(/sc/h/2trdv572mgv5pi5h9lfszlax7);
  --checkbox-checked-background-position: 0 -2.4rem;
  --checkbox-checked-disabled-background-position: 0 -36.6rem;
  --select-chevron-background-position: top -31.1rem right .7rem;
  --select-chevron-background-position-rtl: top -31.1rem left .7rem;
  --acttachment-icon-filter: brightness(1);
  --image-filter: invert(0%);
  --delay-short: 52ms;
  --delay-medium: 84ms;
  --duration-xxfast: 52ms;
  --duration-xfast: 84ms;
  --duration-fast: 132ms;
  --duration-moderate: 216ms;
  --duration-slow: 336ms;
  --duration-xslow: 560ms;
  --duration-xxslow: 916ms;
  --ease-standard: cubic-bezier(.34,0,.21,1);
  --ease-emphasized: cubic-bezier(.13,0,.08,1);
  --ease-decelerate: cubic-bezier(0,0,0.21,1);
  --ease-accelerate: cubic-bezier(0.55,0,1,1);
  --motion-transition-slide-in: var(--duration-fast) var(--ease-decelerate);
  --motion-transition-slide-out: var(--duration-fast) var(--ease-accelerate);
  --motion-transition-appear: var(--duration-xxfast) var(--ease-standard);
  --motion-transition-hide: var(--duration-xxfast) var(--ease-standard);
  --motion-transition-hover-in: var(--duration-xfast) var(--ease-standard);
  --motion-transition-hover-out: var(--duration-xfast) var(--ease-standard);
  --motion-transition-hover-in-raised: var(--duration-fast) var(--ease-standard);
  --motion-transition-hover-out-raised: var(--duration-fast) var(--ease-standard);
  --motion-transition-expanding: var(--duration-xxfast) var(--ease-standard);
  --motion-transition-hover-shrinking: var(--duration-xxfast) var(--ease-standard);
  --motion-transition-zoom-in: var(--duration-fast) var(--ease-decelerate);
  --motion-transition-hover-zoom-out: var(--duration-fast) var(--ease-accelerate);
  --corner-radius-small: 0.4rem;
  --corner-radius-medium: 0.8rem;
  --corner-radius-large: 1.6rem;
  --corner-radius-xlarge: 2.4rem;
  --corner-radius-full: 50%;
  --free: var(--corner-radius-medium);
  --attached-top: 0 0 var(--corner-radius-medium) var(--corner-radius-medium);
  --attached-right: var(--corner-radius-medium) 0 0 var(--corner-radius-medium);
  --attached-bottom: var(--corner-radius-medium) var(--corner-radius-medium) 0 0;
  --attached-left: 0 var(--corner-radius-medium) var(--corner-radius-medium) 0;
  --point-top-left: 0 var(--corner-radius-medium) var(--corner-radius-medium) var(--corner-radius-medium);
  --point-top-right: var(--corner-radius-medium) 0 var(--corner-radius-medium) var(--corner-radius-medium);
  --point-bottom-right: var(--corner-radius-medium) var(--corner-radius-medium) 0 var(--corner-radius-medium);
  --point-bottom-left: var(--corner-radius-medium) var(--corner-radius-medium) var(--corner-radius-medium) 0;
  --spacing-half-x: 0.4rem;
  --spacing-one-x: 0.8rem;
  --spacing-one-and-a-half-x: 1.2rem;
  --spacing-two-x: 1.6rem;
  --spacing-three-x: 2.4rem;
  --spacing-four-x: 3.2rem;
  --spacing-six-x: 4.8rem;
  --spacing-eight-x: 6.4rem;
  --spacing-twelve-x: 9.6rem;
  --spacing-sixteen-x: 12.8rem;
  --spacing-twenty-four-x: 19.2rem;
  --spacing-thirty-two-x: 25.6rem;
  --font-size-xsmall: 1.2rem;
  --font-size-small: 1.4rem;
  --font-size-medium: 1.6rem;
  --font-size-large: 2rem;
  --font-size-xlarge: 2.4rem;
  --font-size-display-small: 2.4rem;
  --font-size-display-medium: 3.2rem;
  --font-size-display-large: 4.8rem;
  --font-size-display-xlarge: 6.4rem;
  --font-weight-regular: 400;
  --font-weight-bold: 600;
  --line-height-default: 1.25;
  --line-height-open: 1.5;
  --paragraph-spacing: 1.2rem;
  --color-transparent: var(--transparent-white);
  --color-transparent-on-on-dark: var(--transparent-black);
  --color-brand: var(--blue-70);
  --color-brand-active: var(--blue-80);
  --color-brand-alt: var(--black);
  --color-brand-alt-active: var(--black);
  --color-brand-on-dark: var(--white-a90);
  --color-brand-on-dark-active: var(--white-a70);
  --color-brand-accent-1: var(--amber-70);
  --color-brand-accent-1-active: var(--amber-80);
  --color-brand-accent-1-on-dark: var(--amber-40);
  --color-brand-accent-1-on-dark-active: var(--amber-50);
  --color-background-brand-accent-1: var(--amber-30);
  --color-background-brand-accent-1-dark: var(--amber-80);
  --color-brand-accent-2: var(--copper-70);
  --color-brand-accent-2-active: var(--copper-80);
  --color-brand-accent-2-on-dark: var(--copper-40);
  --color-brand-accent-2-on-dark-active: var(--copper-50);
  --color-background-brand-accent-2: var(--copper-30);
  --color-background-brand-accent-2-dark: var(--copper-80);
  --color-brand-accent-3: var(--sage-70);
  --color-brand-accent-3-active: var(--sage-80);
  --color-brand-accent-3-on-dark: var(--sage-40);
  --color-brand-accent-3-on-dark-active: var(--sage-50);
  --color-background-brand-accent-3: var(--sage-30);
  --color-background-brand-accent-3-dark: var(--sage-80);
  --color-brand-accent-4: var(--cool-gray-70);
  --color-brand-accent-4-active: var(--cool-gray-80);
  --color-brand-accent-4-on-dark: var(--cool-gray-40);
  --color-brand-accent-4-on-dark-active: var(--cool-gray-50);
  --color-background-brand-accent-4: var(--cool-gray-30);
  --color-background-brand-accent-4-dark: var(--cool-gray-80);
  --color-brand-accent-5: var(--warm-gray-70);
  --color-brand-accent-5-active: var(--warm-gray-80);
  --color-brand-accent-5-on-dark: var(--warm-gray-40);
  --color-brand-accent-5-on-dark-active: var(--warm-gray-50);
  --color-background-brand-accent-5: var(--warm-gray-30);
  --color-background-brand-accent-5-dark: var(--warm-gray-80);
  --color-action: var(--blue-70);
  --color-action-active: var(--blue-80);
  --color-action-on-dark: var(--blue-50);
  --color-action-on-dark-active: var(--blue-40);
  --color-signal-positive: var(--system-green-70);
  --color-signal-positive-active: var(--system-green-80);
  --color-signal-positive-on-dark: var(--system-green-50);
  --color-signal-positive-on-dark-active: var(--system-green-40);
  --color-signal-caution: var(--system-orange-70);
  --color-signal-caution-active: var(--system-orange-80);
  --color-signal-caution-on-dark: var(--system-orange-50);
  --color-signal-caution-on-dark-active: var(--system-orange-40);
  --color-signal-negative: var(--system-red-70);
  --color-signal-negative-active: var(--system-red-80);
  --color-signal-negative-on-dark: var(--system-red-50);
  --color-signal-negative-on-dark-active: var(--system-red-40);
  --color-signal-neutral: var(--cool-gray-70);
  --color-signal-neutral-active: var(--cool-gray-80);
  --color-signal-neutral-on-dark: var(--cool-gray-50);
  --color-signal-neutral-on-dark-active: var(--cool-gray-40);
  --color-text: var(--black-a90);
  --color-text-active: var(--black-a90);
  --color-text-on-dark: var(--white-a90);
  --color-text-on-dark-active: var(--white-a90);
  --color-text-stick: var(--black-a90);
  --color-text-stick-active: var(--black-a90);
  --color-text-on-dark-flip: var(--white);
  --color-text-on-dark-flip-active: var(--white-a70);
  --color-text-shift-on-dark-flip: var(--white);
  --color-text-shift-on-dark-flip-active: var(--white-a90);
  --color-text-low-emphasis: var(--black-a60);
  --color-text-low-emphasis-active: var(--black-a90);
  --color-text-low-emphasis-on-dark: var(--white-a60);
  --color-text-low-emphasis-on-dark-active: var(--white-a90);
  --color-text-low-emphasis-shift: var(--black-a60);
  --color-text-low-emphasis-shift-active: var(--black-a90);
  --color-text-low-emphasis-stick: var(--black-a60);
  --color-text-low-emphasis-stick-active: var(--black-a90);
  --color-text-disabled: var(--black-a30);
  --color-text-on-dark-disabled: var(--white-a40);
  --color-text-link-visited: var(--purple-70);
  --color-text-link-visited-active: var(--purple-80);
  --color-text-link-visited-on-dark: var(--purple-40);
  --color-text-link-visited-on-dark-active: var(--purple-50);
  --color-text-solid: var(--system-gray-90);
  --color-text-solid-active: var(--system-gray-90);
  --color-text-solid-on-dark: var(--system-gray-20);
  --color-text-solid-on-dark-active: var(--system-gray-40);
  --color-text-solid-low-emphasis: var(--system-gray-70);
  --color-text-solid-low-emphasis-active: var(--system-gray-90);
  --color-text-solid-low-emphasis-on-dark: var(--system-gray-40);
  --color-text-solid-low-emphasis-on-dark-active: var(--system-gray-50);
  --color-text-solid-disabled: var(--system-gray-50);
  --color-text-solid-on-dark-disabled: var(--system-gray-60);
  --color-icon: var(--black-a60);
  --color-icon-active: var(--black-a90);
  --color-icon-on-dark: var(--white-a90);
  --color-icon-on-dark-active: var(--white-a90);
  --color-icon-stick: var(--black-a60);
  --color-icon-stick-active: var(--black-a90);
  --color-icon-on-dark-flip: var(--white-a90);
  --color-icon-on-dark-flip-active: var(--white-a70);
  --color-icon-nav: var(--black-a60);
  --color-icon-nav-active: var(--black-a90);
  --color-icon-nav-on-dark: var(--white-a70);
  --color-icon-nav-on-dark-active: var(--white);
  --color-icon-nav-selected: var(--black-a90);
  --color-icon-nav-selected-active: var(--black-a90);
  --color-icon-nav-selected-on-dark: var(--white-a90);
  --color-icon-nav-selected-on-dark-active: var(--white-a90);
  --color-icon-disabled: var(--black-a30);
  --color-icon-on-dark-disabled: var(--white-a40);
  --color-border: var(--black-a60);
  --color-border-active: var(--black-a90);
  --color-border-on-dark: var(--white-a90);
  --color-border-on-dark-active: var(--white-a90);
  --color-border-low-emphasis: var(--black-a30);
  --color-border-low-emphasis-active: var(--black-a60);
  --color-border-low-emphasis-on-dark: var(--white-a40);
  --color-border-low-emphasis-on-dark-active: var(--white-a25);
  --color-border-faint: var(--black-a08);
  --color-border-faint-on-dark: var(--white-a12);
  --color-border-gap: var(--white);
  --color-border-gap-on-dark: var(--black-a60);
  --color-background-container: var(--white);
  --color-background-container-tint: var(--cool-gray-10);
  --color-background-container-dark: var(--cool-gray-90);
  --color-background-container-dark-tint: var(--cool-gray-85);
  --color-background-canvas: var(--warm-gray-20);
  --color-background-canvas-mobile: var(--warm-gray-30);
  --color-background-canvas-tint: var(--white);
  --color-background-canvas-dark: var(--black);
  --color-background-canvas-dark-tint: var(--cool-gray-90);
  --color-background-scrim: var(--black-a60);
  --color-background-scrim-dark: var(--black-a90);
  --color-background-new: var(--blue-50-a20);
  --color-background-new-active: var(--blue-50-a30);
  --color-background-new-on-dark: var(--blue-50-a30);
  --color-background-new-on-dark-active: var(--blue-50-a20);
  --color-element: var(--black-a90);
  --color-element-active: var(--black-a90);
  --color-element-on-dark: var(--white);
  --color-element-on-dark-active: var(--white-a70);
  --color-element-low-emphasis: var(--black-a60);
  --color-element-low-emphasis-active: var(--black-a90);
  --color-element-low-emphasis-on-dark: var(--white-a70);
  --color-element-low-emphasis-on-dark-active: var(--white-a70);
  --color-element-low-emphasis-shift: var(--black-a60);
  --color-element-low-emphasis-shift-active: var(--black-a90);
  --color-element-low-emphasis-stick: var(--black-a60);
  --color-element-low-emphasis-stick-active: var(--black-a90);
  --color-element-shift-on-dark-flip: var(--white);
  --color-element-shift-on-dark-flip-active: var(--white-a70);
  --color-element-solid: var(--system-gray-90);
  --color-element-solid-active: var(--system-gray-90);
  --color-element-solid-on-dark: var(--white);
  --color-element-solid-on-dark-active: var(--system-gray-40);
  --color-element-solid-low-emphasis: var(--system-gray-70);
  --color-element-solid-low-emphasis-active: var(--system-gray-90);
  --color-element-solid-low-emphasis-on-dark: var(--system-gray-40);
  --color-element-solid-low-emphasis-on-dark-active: var(--system-gray-50);
  --color-element-solid-low-emphasis-shift: var(--system-gray-70);
  --color-element-solid-low-emphasis-shift-active: var(--system-gray-90);
  --color-element-solid-low-emphasis-stick: var(--system-gray-70);
  --color-element-solid-low-emphasis-stick-active: var(--system-gray-90);
  --color-element-solid-shift-on-dark-flip: var(--white);
  --color-element-solid-shift-on-dark-flip-active: var(--system-gray-40);
  --color-element-solid-disabled: var(--system-gray-40);
  --color-element-solid-on-dark-disabled: var(--system-gray-70);
  --color-background-faint: var(--black-a08);
  --color-background-faint-active: var(--black-a15);
  --color-background-faint-on-dark: var(--white-a12);
  --color-background-faint-on-dark-active: var(--white-a06);
  --color-checked: var(--system-green-70);
  --color-checked-active: var(--system-green-80);
  --color-checked-tint: var(--system-green-30);
  --color-checked-on-dark: var(--system-green-40);
  --color-checked-on-dark-active: var(--system-green-50);
  --color-checked-tint-on-dark: var(--system-green-80);
  --color-checked-offset: var(--system-green-60);
  --color-checked-offset-active: var(--system-green-70);
  --color-checked-offset-on-dark: var(--system-green-50);
  --color-checked-offset-on-dark-active: var(--system-green-60);
  --color-background-none: var(--color-transparent);
  --color-background-none-hover: var(--color-transparent);
  --color-background-none-active: var(--black-a08);
  --color-background-none-on-dark: var(--color-transparent);
  --color-background-none-on-dark-hover: var(--color-transparent);
  --color-background-none-on-dark-active: var(--white-a12);
  --color-background-none-action-active: var(--blue-50-a20);
  --color-background-none-action-on-dark-active: var(--blue-50-a20);
  --color-background-none-tint-hover: var(--black-a08);
  --color-background-none-tint-active: var(--black-a12);
  --color-background-none-tint-on-dark-hover: var(--white-a18);
  --color-background-none-tint-on-dark-active: var(--white-a12);
  --color-background-none-tint-action-hover: var(--blue-50-a20);
  --color-background-none-tint-action-active: var(--blue-50-a30);
  --color-background-none-tint-action-on-dark-hover: var(--blue-50-a30);
  --color-background-none-tint-action-on-dark-active: var(--blue-50-a20);
  --color-disabled: var(--black-a30);
  --color-on-dark-disabled: var(--white-a40);
  --color-background-disabled: var(--black-a08);
  --color-background-on-dark-disabled: var(--white-a12);
  --color-border-focus: var(--teal-70);
  --color-background-focus: var(--teal-50-a30);
  --color-border-focus-on-dark: var(--teal-40);
  --color-background-focus-on-dark: var(--teal-50-a30);
  --color-shadow: var(--black-a30);
  --color-shadow-on-dark: var(--black-a60);
  --voyager-color-action: var(--blue-70);
  --voyager-color-action-hover: var(--blue-80);
  --voyager-color-action-active: var(--blue-90);
  --voyager-color-action-on-dark: var(--white);
  --voyager-color-action-on-dark-hover: var(--white);
  --voyager-color-action-on-dark-active: var(--white-a70);
  --voyager-color-action-stick: var(--blue-70);
  --voyager-color-action-stick-hover: var(--blue-80);
  --voyager-color-action-stick-active: var(--blue-90);
  --voyager-color-action-selected: var(--cool-gray-20);
  --voyager-color-action-on-dark-selected: var(--cool-gray-80);
  --memorialization-color-border: var(--lavender-80);
  --memorialization-color-label: var(--lavender-80);
  --memorialization-color-canvas: var(--lavender-10);
  --memorialization-color-border-on-dark: var(--lavender-40);
  --memorialization-color-background-label-on-dark: var(--lavender-40);
  --premium-color-brand: var(--amber-40);
  --premium-color-action: var(--amber-40);
  --premium-color-action-hover: var(--amber-50);
  --premium-color-action-active: var(--amber-60);
  --premium-color-text: var(--black-a90);
  --premium-color-text-active: var(--black-a90);
  --premium-color-icon: var(--black-a90);
  --premium-color-icon-active: var(--black-a90);
  --premium-color-plan-1: var(--green-70);
  --premium-color-plan-2: var(--cool-gray-70);
  --premium-color-plan-3: var(--mauve-70);
  --premium-color-plan-4: var(--mauve-80);
  --premium-color-plan-5: var(--copper-70);
  --premium-color-plan-6: var(--teal-70);
  --premium-color-inbug: var(--amber-60);
  --reactions-color-text-like: var(--blue-70);
  --reactions-color-text-like-on-dark: var(--blue-40);
  --reactions-color-text-celebrate: var(--green-70);
  --reactions-color-text-celebrate-on-dark: var(--green-40);
  --reactions-color-text-support: var(--lavender-70);
  --reactions-color-text-support-on-dark: var(--lavender-40);
  --reactions-color-text-love: var(--warm-red-70);
  --reactions-color-text-love-on-dark: var(--warm-red-40);
  --reactions-color-text-insightful: var(--amber-70);
  --reactions-color-text-insightful-on-dark: var(--amber-40);
  --reactions-color-text-curious: var(--mauve-70);
  --reactions-color-text-curious-on-dark: var(--mauve-40);
  --reactions-color-content-like: var(--blue-70);
  --reactions-color-content-like-on-dark: var(--blue-40);
  --reactions-color-content-celebrate: var(--green-70);
  --reactions-color-content-celebrate-on-dark: var(--green-40);
  --reactions-color-content-support: var(--lavender-70);
  --reactions-color-content-support-on-dark: var(--lavender-40);
  --reactions-color-content-love: var(--warm-red-70);
  --reactions-color-content-love-on-dark: var(--warm-red-40);
  --reactions-color-content-insightful: var(--amber-70);
  --reactions-color-content-insightful-on-dark: var(--amber-40);
  --reactions-color-content-curious: var(--mauve-70);
  --reactions-color-content-curious-on-dark: var(--mauve-40);
  --voyager-color-background-comment: var(--system-gray-20);
  --stories-color-border-ring: linear-gradient(319.22deg,var(--purple-60) 15.03%,var(--blue-50) 84.54%);
  --stories-color-element-content-consumption-indicator-track: var(--white-a70);
  --stories-color-element-content-consumption-indicator-fill: var(--white);
  --stories-color-element-accent-1-light: var(--system-red-40);
  --stories-color-element-accent-1-dark: var(--system-red-50);
  --stories-color-element-accent-1-darker: var(--system-red-70);
  --stories-color-element-accent-2-lighter: var(--warm-red-30);
  --stories-color-element-accent-2-light: var(--warm-red-40);
  --stories-color-element-accent-2-dark: var(--warm-red-50);
  --stories-color-element-accent-2-darker: var(--warm-red-70);
  --stories-color-element-accent-3-lighter: var(--system-green-30);
  --stories-color-element-accent-3-light: var(--system-green-40);
  --stories-color-element-accent-3-dark: var(--system-green-50);
  --stories-color-element-accent-3-darker: var(--system-green-70);
  --stories-color-element-accent-4-lighter: var(--blue-30);
  --stories-color-element-accent-4-light: var(--blue-40);
  --stories-color-element-accent-4-dark: var(--blue-50);
  --stories-color-element-accent-4-darker: var(--blue-70);
  --stories-color-element-accent-5-lighter: var(--purple-30);
  --stories-color-element-accent-5-light: var(--purple-40);
  --stories-color-element-accent-5-dark: var(--purple-50);
  --stories-color-element-accent-5-darker: var(--purple-70);
  --stories-color-element-accent-6-lighter: var(--cool-gray-30);
  --stories-color-element-accent-6-light: var(--cool-gray-40);
  --stories-color-element-accent-6-dark: var(--cool-gray-50);
  --stories-color-element-accent-6-darker: var(--cool-gray-70);
  --post-color-icon-photo: var(--blue-50);
  --post-color-icon-video: var(--green-50);
  --post-color-icon-event: var(--purple-50);
  --post-color-icon-article: var(--system-red-50);
  --voyager-color-background-slider-track: var(--system-gray-60);
  --voyager-color-background-container-tint-2: var(--cool-gray-20);
  --voyager-color-background-input-search: var(--cool-gray-20);
  --voyager-color-background-input-search-active: var(--cool-gray-10);
  --voyager-color-background-input-search-on-dark: var(--cool-gray-80);
  --voyager-color-background-input-search-on-dark-active: var(--cool-gray-90);
  --voyager-color-background-coachmark: var(--white);
  --voyager-color-background-coachmark-inner-dot: var(--purple-70);
  --voyager-color-background-coachmark-outer-dot: var(--purple-70-a15);
  --voyager-color-background-coachmark-on-dark: var(--cool-gray-90);
  --voyager-color-background-coachmark-inner-dot-on-dark: var(--purple-40);
  --voyager-color-background-coachmark-outer-dot-on-dark: var(--purple-40-a15);
  --voyager-color-icon-accent-person: var(--color-brand-accent-4);
  --voyager-color-icon-accent-person-on-dark: var(--color-brand-accent-4-on-dark);
  --voyager-color-icon-accent-company: var(--color-brand-accent-5);
  --voyager-color-icon-accent-company-on-dark: var(--color-brand-accent-5-on-dark);
  --voyager-color-icon-accent-school: var(--color-brand-accent-3);
  --voyager-color-icon-accent-school-on-dark: var(--color-brand-accent-3-on-dark);
  --voyager-color-icon-accent-group: var(--color-brand-accent-1);
  --voyager-color-icon-accent-group-on-dark: var(--color-brand-accent-3-on-dark);
  --voyager-color-icon-accent-hashtag: var(--color-brand-accent-4);
  --voyager-color-icon-accent-hashtag-on-dark: var(--color-brand-accent-4-on-dark);
  --voyager-color-icon-accent-event: var(--color-brand-accent-2);
  --voyager-color-icon-accent-event-on-dark: var(--color-brand-accent-2-on-dark);
  --voyager-color-icon-accent-publication: var(--color-brand-accent-4);
  --voyager-color-icon-accent-publication-on-dark: var(--color-brand-accent-4-on-dark);
  --voyager-color-qr-code: var(--black);
  --voyager-color-qr-code-background: var(--white);
  --voyager-color-skeleton: var(--black-a08);
  --voyager-color-skeleton-content: var(--black-a08);
  --voyager-color-skeleton-content-on-dark: var(--white-a12);
  --voyager-color-skeleton-content-shimmer: linear-gradient(-30deg,var(--color-transparent),var(--warm-gray-40-a50),var(--color-transparent));
  --voyager-color-skeleton-content-shimmer-on-dark: linear-gradient(-30deg,var(--color-transparent-on-dark),var(--white-a06),var(--color-transparent-on-dark));
  --voyager-color-background-scrim-tint: var(--white-a85);
  --voyager-color-background-attachment-hover: var(--white-a85);
  --voyager-color-background-attachment-pdf: var(--system-red-70);
  --voyager-color-background-attachment-pdf-on-dark: var(--system-red-40);
  --voyager-color-background-attachment-txt: var(--purple-70);
  --voyager-color-background-attachment-txt-on-dark: var(--purple-40);
  --voyager-color-background-attachment-psd: var(--blue-70);
  --voyager-color-background-attachment-psd-on-dark: var(--blue-40);
  --voyager-color-background-attachment-ai: var(--warm-red-70);
  --voyager-color-background-attachment-ai-on-dark: var(--warm-red-40);
  --voyager-color-background-attachment-ppt: var(--warm-red-70);
  --voyager-color-background-attachment-ppt-on-dark: var(--warm-red-40);
  --voyager-color-background-attachment-doc: var(--blue-80);
  --voyager-color-background-attachment-doc-on-dark: var(--blue-40);
  --voyager-color-background-attachment-xls: var(--system-green-70);
  --voyager-color-background-attachment-xls-on-dark: var(--system-green-40);
  --voyager-color-background-attachment-default: var(--cool-gray-70);
  --voyager-color-background-attachment-default-on-dark: var(--cool-gray-40);
  --voyager-color-element-loading: var(--black-a60);
  --voyager-color-element-loading-on-dark: var(--white-a70);
  --voyager-color-background-badge-notification: var(--system-red-70);
  --voyager-color-background-badge-new: var(--blue-70);
  --voyager-color-background-badge-selection: var(--system-green-70);
  --voyager-color-background-badge-presence: var(--system-green-70);
  --voyager-color-element-rating-input: var(--color-icon);
  --voyager-color-element-rating-input-on-dark: var(--color-icon-on-dark);
  --voyager-color-element-rating-display: var(--amber-60);
  --voyager-color-element-rating-display-on-dark: var(--amber-50);
  --identity-color-background-label: var(--amber-30);
  --identity-color-border-ring: linear-gradient(135deg,var(--warm-red-60) 25%,var(--amber-30) 75%);
  --identity-color-icon-create: var(--warm-red-60);
  --live-color-live-indicator: var(--system-red-70);
  --live-color-background-comment: var(--system-gray-20);
  --live-color-background-like: var(--white);
  --voyager-color-background-overlay: var(--black-a60);
  --voyager-color-background-overlay-hover: var(--black-a75);
  --voyager-color-background-overlay-active: var(--black-a90);
  --voyager-color-background-overlay-gradient-to-top: linear-gradient(0deg,var(--color-transparent) 0%,var(--voyager-color-background-overlay));
  --voyager-color-background-overlay-gradient-to-bottom: linear-gradient(180deg,var(--color-transparent) 0%,var(--voyager-color-background-overlay));
  --stories-color-background-overlay: linear-gradient(180deg,var(--black-a75),var(--black-a60) 25%,var(--black-a45) 50%,var(--black-a75));
  --messenger-color-background-input-message: var(--warm-gray-20);
  --messenger-color-background-input-message-active: var(--warm-gray-30);
  --growth-color-invitation-accent-groups: var(--smoke-60);
  --growth-color-invitation-accent-events: var(--teal-60);
  --growth-color-invitation-accent-newsletter: var(--green-60);
  --growth-color-invitation-accent-pages: var(--purple-60);
  --data-viz-color-accent-1: var(--blue-60);
  --data-viz-color-accent-2: var(--teal-80);
  --data-viz-color-accent-3: var(--warm-red-60);
  --data-viz-color-accent-4: var(--warm-gray-80);
  --data-viz-color-accent-5: var(--lime-60);
  --data-viz-color-accent-6: var(--blue-80);
  --data-viz-color-accent-7: var(--amber-60);
  --data-viz-color-accent-8: var(--pink-80);
  --artdeco-reset-base-margin-zero: 0;
  --artdeco-reset-base-padding-zero: 0;
  --artdeco-reset-base-border-zero: 0;
  --artdeco-reset-base-font-size-hundred-percent: 100%;
  --artdeco-reset-base-font-weight-bold: bold;
  --artdeco-reset-base-font-style-italic: italic;
  --artdeco-reset-base-outline-zero: 0;
  --artdeco-reset-base-outline-none: none;
  --artdeco-reset-base-line-height-one: 1;
  --artdeco-reset-base-display-block: block;
  --artdeco-reset-base-list-style-none: none;
  --artdeco-reset-base-quotes-none: none;
  --artdeco-reset-base-vertical-align-baseline: baseline;
  --artdeco-reset-base-vertical-align-middle: middle;
  --artdeco-reset-base-background-transparent: transparent;
  --artdeco-reset-base-opacity-zero: 0;
  --artdeco-reset-base-top-zero: 0;
  --artdeco-reset-base-position-absolute: absolute;
  --artdeco-reset-base-text-decoration-none: none;
  --artdeco-reset-base-text-decoration-line-through: line-through;
  --artdeco-reset-base-border-collapse-collapse: collapse;
  --artdeco-reset-base-get-color-black: var(--black,#000);
  --artdeco-reset-base-background-color-ff9: #ff9;
  --artdeco-reset-base-border-spacing-zero: 0;
  --artdeco-reset-base-cursor-help: help;
  --artdeco-reset-base-content-none: none;
  --artdeco-reset-base-left-minus-hundred-px: -100px;
  --artdeco-reset-base-border-thickness-1-px: 1px;
  --artdeco-reset-base-border-style-dotted: dotted;
  --artdeco-reset-form-position-relative: relative;
  --artdeco-reset-form-vertical-align-middle: middle;
  --artdeco-reset-form-display-block: block;
  --artdeco-reset-form-black-90: rgba(0,0,0,0.9);
  --artdeco-reset-form-label-margin: 1.2rem 0 0.3rem;
  --artdeco-reset-form-label-opacity: 0.35;
  --artdeco-reset-form-webkit-appearance-textfield: textfield;
  --artdeco-reset-form-webkit-appearance-none: none;
  --artdeco-reset-form-height-auto: auto;
  --artdeco-reset-form-padding-top-point-seven-rem: 0.7rem;
  --artdeco-reset-form-rc-pointer-events: none;
  --artdeco-reset-form-rc-opacity: 0;
  --artdeco-reset-form-rc-margin: 1.6rem 0.2rem 0 0;
  --artdeco-reset-form-rc-position: absolute;
  --artdeco-reset-form-rc-before-after-content: " ";
  --artdeco-reset-checkbox-rc-after-content: "\2714";
  --artdeco-reset-form-rc-label-display-block: block;
  --artdeco-reset-form-rc-label-line-height-2-rem: 2rem;
  --artdeco-reset-form-rc-label-margin-bottom-zero: 0;
  --artdeco-reset-form-rc-label-padding-zero: 0;
  --artdeco-reset-form-rc-label-position-relative: relative;
  --artdeco-reset-form-rc-label-padding-left-2point8-rem: 2.8rem;
  --artdeco-reset-forms-input-transition-duration: 334ms;
  --artdeco-reset-forms-input-transition-property: border-color,box-shadow;
  --artdeco-reset-forms-input-box-shadow: none;
  --artdeco-reset-forms-input-border-radius: 0.2rem;
  --artdeco-reset-forms-input-border: 0.1rem solid rgba(0,0,0,0.6);
  --artdeco-reset-forms-input-padding: 0 1rem;
  --artdeco-reset-forms-input-width: 100%;
  --artdeco-reset-forms-input-height: 3.2rem;
  --artdeco-reset-forms-input-box-sizing: border-box;
  --artdeco-reset-forms-input-background-color: transparent;
  --artdeco-reset-forms-input-color: rgba(0,0,0,0.9);
  --artdeco-reset-forms-input-placeholder-color: rgba(0,0,0,0.6);
  --artdeco-reset-forms-input-blue: var(--blue-70,#0073b1);
  --artdeco-reset-forms-input-focus-box-shadow: 0 0 0 0.1rem var(--blue-70,#0073b1);
  --artdeco-reset-forms-input-disabled-hover-border-color: rgba(0,0,0,0.6);
  --artdeco-reset-forms-input-disabled-opacity: 0.45;
  --artdeco-reset-forms-input-error: var(--system-red-70,#d11124);
  --artdeco-reset-forms-font-weight: 400;
  --artdeco-reset-forms-font-size: 1.4rem;
  --artdeco-reset-forms-line-height: 1.42857;
  --artdeco-reset-forms-select-appearance-none: none;
  --artdeco-reset-forms-select-box-shadow-none: none;
  --artdeco-reset-forms-select-outline-zero: 0;
  --artdeco-reset-forms-select-height-3point2-rem: 3.2rem;
  --artdeco-reset-forms-select-background-transparent: transparent;
  --artdeco-reset-forms-select-position-relative: relative;
  --artdeco-reset-forms-select-zindex-two: 2;
  --artdeco-reset-forms-select-background-image: url(/sc/h/cwn0a0e7hog2i33c88ucrvot5);
  --artdeco-reset-forms-select-border-box: border-box;
  --artdeco-reset-forms-select-border-zero: 0;
  --artdeco-reset-forms-select-width-100-percent: 100%;
  --artdeco-reset-forms-select-border-radius-point2rem: 0.2rem;
  --artdeco-reset-forms-select-border: 0.1rem solid rgba(0,0,0,0.9);
  --artdeco-reset-forms-select-padding: 0 3.2rem 0 0.8rem;
  --artdeco-reset-forms-select-transition: box-shadow 0.15s;
  --artdeco-reset-forms-select-disabled-opacity: 0.45;
  --artdeco-reset-forms-select-hover-border-color: rgba(0,0,0,0.9);
  --artdeco-reset-forms-select-focus-border-color: var(--blue-70,#0073b1);
  --artdeco-reset-forms-select-focus-box-shadow: 0 0 0 0.1rem var(--blue-70,#0073b1);
  --artdeco-reset-typography-get-color-black-90: rgba(0,0,0,0.9);
  --artdeco-reset-typography-font-family-sans: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
  --artdeco-reset-typography-font-size-65-percent: 62.5%;
  --artdeco-reset-typography-font-weight-bold: 600;
  --artdeco-reset-typography-font-weight-light: 200;
  --artdeco-reset-typography-font-weight-normal: 400;
  --artdeco-reset-typography-font-family-inherit: inherit;
  --artdeco-reset-typography-font-style-italic: italic;
  --artdeco-reset-typography-font-style-normal: normal;
  --artdeco-reset-link-text-decoration-none: none;
  --artdeco-reset-link-font-weight-bold: 600;
  --artdeco-reset-link-background-color-transparent: transparent;
  --artdeco-reset-link-border-zero: 0;
  --artdeco-reset-link-color-blue7: var(--blue-70,#0073b1);
  --artdeco-reset-link-text-decoration-underline: underline;
  --artdeco-reset-link-color-blue8: var(--blue-70,#006097);
  --artdeco-reset-link-color-blue9: var(--blue-80,#004b7c);
  --artdeco-reset-link-color-purple7: var(--purple-70,#665ed0);
  --artdeco-reset-link-color-purple8: var(--purple-70,#544bc2);
  --artdeco-reset-link-color-purple9: var(--purple-80,#4034b0);
  --artdeco-typography-mono: SF Mono,Consolas,Roboto Mono,Noto Mono,Droid Mono,Fira Mono,Ubuntu Mono,Oxygen Mono,Lucida Console,Menlo,Monaco,monospace;
  --artdeco-typography-sans: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
  --artdeco-typography-serif: Noto Serif,Droid Serif,Georgia,serif;
  --artdeco-typography-ar: Arabic UI Display,Geeza Pro,Simplified Arabic,var(--artdeco-typography-sans);
  --artdeco-typography-ja: Meiryo,Yu Gothic,Hiragino Kaku Gothic Pro,Hiragino Sans,var(--artdeco-typography-sans);
  --artdeco-typography-ko: Malgun Gothic,Apple SD Gothic Neo,var(--artdeco-typography-sans);
  --artdeco-typography-th: Leelawadee,Thonburi,var(--artdeco-typography-sans);
  --artdeco-typography-zh: Microsoft Yahei,PingFang SC,PingFang TC,Hiragino Sans,Hiragino Kaku Gothic Pro,var(--artdeco-typography-sans);
  --artdeco-typography-hi: Kohinoor Devanagari,Mangal,var(--artdeco-typography-sans);
  --premium-branding-large: url(/sc/h/es2035ne9ivdvq8vj7n92cnh7);
  --scaffold-layout-none-max-width: 576px;
  --scaffold-layout-md-max-width: 720px;
  --scaffold-layout-lg-max-width: 960px;
  --scaffold-layout-xl-max-width: 1128px;
  --scaffold-layout-list-detail-bottom-offset: 50px;
  --identity-phone-promo-image: url(/sc/h/bga5knjr380vj899lmrz5fjdo);
  --msg-bluejeans-virtual-meeting-icon: url(/sc/h/4pwxmk5zdrww2xgpzh1s6w8wo);
  --msg-teams-virtual-meeting-icon: url(/sc/h/anrbpluz8oa3k1ijq55l0hbas);
  --msg-zoom-virtual-meeting-icon: url(/sc/h/6jeeyg4s09feux6cv5aanxzvk);
  --tenor-gif-logo: url(/sc/h/d2kkhofmuuxleioyci47pssdd);
  --org-addon-bing-logo: url(/sc/h/a962xew3q1hfeb3mlcjj1ux8o);
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: var(--artdeco-reset-typography-font-family-sans);
  --scaffold-layout-gutter: 2.4rem;
  list-style: none inside;
  font-style: var(--artdeco-reset-typography-font-style-normal);
  font-weight: var(--artdeco-reset-typography-font-weight-normal);
  --artdeco-reset-typography_getFontSize: 1.4rem;
  --artdeco-reset-typography_getLineHeight: 1.42857;
  line-height: var(--artdeco-reset-typography_getLineHeight);
  color: var(--color-text-low-emphasis);
  box-sizing: inherit;
  margin: var(--artdeco-reset-base-margin-zero);
  padding: var(--artdeco-reset-base-padding-zero);
  border: var(--artdeco-reset-base-border-zero);
  font-size: var(--artdeco-reset-base-font-size-hundred-percent);
  vertical-align: var(--artdeco-reset-base-vertical-align-baseline);
  background: var(--artdeco-reset-base-background-transparent);
  outline: var(--artdeco-reset-base-outline-zero);
}
.posCompanyName {
  font-weight: bolder;
  color: black;
}

.buttons {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.buttons button {
  margin: 15px;
}